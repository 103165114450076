import { fetchAPI } from './fetch-api';

export interface ILearningAim {
  learningAimId: number;
  learningAimTitle: string;
}

const ReferenceDataFetcher = {
  getLearningAims: async () => {
    const response = await fetchAPI<ILearningAim[]>({
      path: 'ReferenceData/Standards',
    });
    if (response.success) {
      return response.body;
    }
    return Promise.reject();
  },
};

export default ReferenceDataFetcher;
