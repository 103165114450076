import React, { SyntheticEvent } from 'react';
import ReactGA from '../../../utils/google-analytics';
import { ICourse } from '../../../context-providers/course/course-types';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import { fetchAPI } from '../../../utils/fetch-api';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import Stack from '../../atoms/stack/Stack';
import { IconDelete } from '../../icons';

interface IDeleteCourse {
  course: ICourse;
  onComplete: () => void;
}

const DeleteCourse = ({ course, onComplete }: IDeleteCourse) => {
  ReactGA.event({
    category: 'Button',
    action: 'Delete course modal - Delete',
  });

  const closeModal = useCloseModal();

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    // send form data to api
    const res = await fetchAPI({
      path: `Courses/${course.id}`,
      method: 'DELETE',
      responseType: 'text',
    });
    if (res.success) {
      onComplete && onComplete();
      closeModal();
    }
  };

  return (
    <form
      onSubmit={(event) => {
        handleSubmit(event);
      }}
    >
      <Stack stackMultiplier={2}>
        <h2>Are you sure?</h2>
        <p>Records can't be restored once deleted</p>
        <ButtonRow>
          <Button
            onClick={() => {
              ReactGA.event({
                category: 'Button',
                action: 'Delete course modal - cancel',
              });
              closeModal();
            }}
            variant="subtle"
            dataQa="deleteCourse-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            variant="warn"
            type="submit"
            dataQa="deleteCourse-btn-delete"
            iconRight={<IconDelete />}
          >
            Delete
          </Button>
        </ButtonRow>
      </Stack>
    </form>
  );
};

export default DeleteCourse;
