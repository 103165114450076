import React, { useState } from 'react';
import ReactGA from '../../../utils/google-analytics';
import { ICourse } from '../../../context-providers/course/course-types';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import { formatDateTime } from '../../../utils/dates';
import { usePagedRequest } from '../../../utils/paged-request';
import Button from '../../atoms/button/Button';
import GridFlex from '../../atoms/grid-flex/GridFlex';
import GridFlexItem from '../../atoms/grid-flex/GridFlexItem';
import Paging from '../../atoms/paging/Paging';
import Stack from '../../atoms/stack/Stack';
import Table from '../../atoms/table/Table';
import { TableActions } from '../../atoms/table/Table.style';
import { IconDelete, IconEdit, IconPlus } from '../../icons';
import { FullWidthContainer } from '../../layout/Layout.style';
import { useUser } from '../../../context-providers/user/user-hooks';
import { Permission } from '../../../context-providers/enums/global-enums';
import { userHasPermission } from '../../../context-providers/user/user-permission';
import { deleteQueryParams, getAllQueryVar } from '../../../utils/query-vars';
import { navigate } from 'gatsby';
import CourseFilter from '../../atoms/filter/filter-types/course-filter';
import { FlexGrid } from '../../atoms/custom-elements/Flex.style';
import TutorNameFilter from '../../atoms/filter/filter-types/tutor-name-filter';
import ActionLink from '../../atoms/custom-elements/linkaction.style';
import { useDetectWindowClose } from '../../../utils/browser';
import EditCourse from '../../modals/course/EditCourse';
import DeleteCourse from '../../modals/course/DeleteCourse';
import CreateCourse from '../../modals/course/CreateCourse';

const isBrowser = typeof window !== 'undefined';

const CourseList = () => {
  if (isBrowser) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  const extractQueryVars = (name: string): string[] => {
    const params = getAllQueryVar(name) ?? [];
    if (params.length === 1) {
      return params[0].split(',');
    }
    return params;
  };

  const coursesParams = extractQueryVars('courses');
  const tutorsParams = extractQueryVars('tutors');
  const hasActiveFilters = coursesParams.length > 0 || tutorsParams.length > 0;

  const showModal = useShowModal();
  const courses = usePagedRequest<ICourse>('Courses', {
    sortColumnName: 'lastModified',
    courses: coursesParams.join(','),
    tutors: tutorsParams.join(','),
  });

  const { user } = useUser();
  let userPermissions: Permission[] = [];
  if (user) {
    userPermissions = user.permissions ? user?.permissions : [];
  }

  const clearUrlParams = () => {
    const url = deleteQueryParams('tutors', 'courses');
    navigate(url || '');
  };

  const clearAllFilters = () => {
    const url = deleteQueryParams('courses', 'tutors');
    navigate(url || '');
  };

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const toggleMenu = (): void => {
    setIsMenuOpen(false);
  };

  const newCourseAdded = () => {
    const url = deleteQueryParams('courses', 'tutors');
    window.location.replace(url);
    window.location.reload;
  };

  const renderRow = (data: ICourse) => [
    <strong key="1">{data.name}</strong>,
    data.learningAimTitle || '-',
    data.activeLearners || '-',
    data.activeEmployers || '-',
    formatDateTime(data.lastModified),
    data.tutors || '-',

    <TableActions key="2">
      <button
        type="button"
        data-qa={`course-btn-edit-${data.name.replace(' ', '-')}`}
        onClick={() => {
          ReactGA.event({
            category: 'Button',
            action: 'Edit course',
          });
          clearUrlParams();
          showModal(EditCourse, { course: data, onComplete: newCourseAdded });
        }}
      >
        <IconEdit responsive />
        <span className="sr-only">Edit {data.name} course</span>
      </button>
      <button
        disabled={
          !!data.activeLearners ||
          !!data.activeEmployers ||
          !userHasPermission(userPermissions, Permission.DeleteCourse)
        }
        type="button"
        data-qa={`course-btn-delete-${data.name.replace(' ', '-')}`}
        onClick={() => {
          ReactGA.event({
            category: 'Button',
            action: 'Delete course',
          });
          showModal(DeleteCourse, {
            course: data,
            onComplete: newCourseAdded,
          });
        }}
      >
        <IconDelete
          fill={
            !!data.activeLearners ||
            !!data.activeEmployers ||
            !userHasPermission(userPermissions, Permission.DeleteCourse)
              ? '#ccc'
              : ''
          }
          responsive
        />
        <span className="sr-only">Delete {data.name} course</span>
      </button>
    </TableActions>,
  ];

  return (
    <FullWidthContainer>
      <Stack stackMultiplier={2}>
        <GridFlex gutters={false} align="flex-end" justify="space-between">
          <GridFlexItem gutters={false} small={8}>
            <Stack stackMultiplier={1}>
              <h1>Course records</h1>
              <p>
                You can manage course records or add new courses to the
                database.
              </p>
            </Stack>
          </GridFlexItem>
          <Button
            disabled={
              !userHasPermission(userPermissions, Permission.CreateCourse)
            }
            iconLeft={<IconPlus responsive />}
            dataQa="course-btn-add"
            onClick={() => {
              ReactGA.event({
                category: 'Button',
                action: 'New course record',
              });
              clearUrlParams();
              showModal(CreateCourse, { onComplete: newCourseAdded });
            }}
          >
            Create course record
          </Button>
        </GridFlex>
        <FlexGrid column={2} row={1}>
          <CourseFilter />
          <TutorNameFilter toggleMenu={toggleMenu} />
          {hasActiveFilters && (
            <ActionLink onClick={clearAllFilters}>Clear all</ActionLink>
          )}
        </FlexGrid>
        <Table
          caption="List of courses"
          data={courses.data}
          headerItems={[
            { label: 'Course name', sortColumnName: 'name' },
            { label: 'Learning Aim', sortColumnName: 'learningAimTitle' },
            { label: 'Learners' },
            { label: 'Employers' },
            { label: 'Last modified', sortColumnName: 'lastModified' },
            { label: 'Tutors' },
            { label: 'Actions' },
          ]}
          paged={courses.pagedParams}
          renderRow={renderRow}
        />
        <Paging pageCount={courses.pageCount} />
      </Stack>
    </FullWidthContainer>
  );
};

export default CourseList;
