import React, { SyntheticEvent, useEffect, useState } from 'react';
import ReactGA from '../../../utils/google-analytics';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import { fetchAPI } from '../../../utils/fetch-api';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';
import {
  CreateCourseHeader,
  CreateCourseSubtitle,
} from '../ModalContent.style';
import { IconPlus } from '../../icons';
import TutorNameFilter from '../../atoms/filter/filter-types/tutor-name-filter';
import { getAllQueryVar } from '../../../utils/query-vars';
import FilterSingleItem from '../../atoms/filter/FilterSingleItem';
import ReferenceDataFetcher, {
  ILearningAim,
} from '../../../utils/referenceDataFetcher';
import { FilterItem } from '../../atoms/filter/models/filter-item';
interface ICreateCourse {
  onComplete: () => void;
}

const CreateCourse = ({ onComplete }: ICreateCourse) => {
  const closeModal = useCloseModal();
  const [course, setCourse] = useState({
    name: '',
    tutors: '',
    learningAimId: 0,
    learningAimTitle: '',
  });

  const [learningAims, setLearningAims] = useState<ILearningAim[]>([]);
  const [isCourseMenuOpen, setIsCourseMenuOpen] = useState<boolean>(false);
  const toggleCourseMenu = (isCourseMenuOpen: boolean): void => {
    setIsCourseMenuOpen(isCourseMenuOpen);
  };
  const [isLearningAimMenuOpen, setIsLearningAimMenuOpen] =
    useState<boolean>(false);
  const toggleLearningAimMenu = (isLearningAimMenuOpen: boolean): void => {
    setIsLearningAimMenuOpen(isLearningAimMenuOpen);
  };

  const getLearningAims = (): void => {
    ReferenceDataFetcher.getLearningAims().then((aimsresponse) => {
      setLearningAims(aimsresponse);
    });
  };

  const learningAimOptions: FilterItem[] = learningAims.map((i) => ({
    id: i.learningAimId,
    name: i.learningAimTitle,
  }));

  const onAimChanged = (selectedItem: FilterItem): void => {
    const learningAim: ILearningAim | undefined = learningAims?.find(
      (l) => l.learningAimId === selectedItem.id,
    );
    const learningAimId = learningAim?.learningAimId ?? 0;
    const learningAimTitle = learningAim?.learningAimTitle ?? '';
    setCourse({ ...course, learningAimId, learningAimTitle });
  };

  const extractQueryVars = (name: string): string[] => {
    const params = getAllQueryVar(name) ?? [];
    if (params.length === 1) {
      return params[0].split(',');
    }
    return params;
  };

  const tutorsParams = extractQueryVars('tutors');

  const handleSubmit = async (event: SyntheticEvent) => {
    ReactGA.event({
      category: 'Button',
      action: 'Create course modal - Add course',
    });

    course.tutors = tutorsParams.join(',');
    event.preventDefault();

    const res = await fetchAPI({
      path: 'Courses',
      body: course,
      method: 'POST',
    });
    if (res.success) {
      onComplete && onComplete();
      closeModal();
    }
  };

  const nameErr = validateField(course.name, { required: true });

  const isValid = !nameErr;

  useEffect(() => {
    getLearningAims();
  }, []);

  return (
    <form
      onSubmit={(event) => {
        setCourse({
          name: course.name,
          tutors: tutorsParams.join(','),
          learningAimId: course.learningAimId,
          learningAimTitle: course.learningAimTitle,
        });
        handleSubmit(event);
      }}
      style={
        isCourseMenuOpen || isLearningAimMenuOpen
          ? { maxWidth: 412, height: 600 }
          : { maxWidth: 412 }
      }
    >
      <Stack stackMultiplier={3}>
        <CreateCourseHeader>
          <IconPlus width={25} height={25} />
          <h2>Create Course Record</h2>
        </CreateCourseHeader>
        <p>
          Tutors are assigned to course records. Course records are assigned to
          Learners and Employers.
        </p>
        <TextInput
          placeholder="Course Name *"
          id="courseName"
          label="Course Name"
          value={course.name}
          error={nameErr}
          dataQa="courseModal-input-name"
          onChangeText={(name) => {
            setCourse({ ...course, name });
          }}
        />
        <CreateCourseSubtitle>Assign Learning Aim</CreateCourseSubtitle>
        <FilterSingleItem
          filterName="standard-filter"
          items={learningAimOptions}
          title="Learning Aim"
          searchTitle="Search for a learning aim"
          onItemChanged={onAimChanged}
          toggleMenu={toggleLearningAimMenu}
        />
        <CreateCourseSubtitle>Assign Tutor(s)</CreateCourseSubtitle>
        <TutorNameFilter toggleMenu={toggleCourseMenu} />
        <ButtonRow>
          <Button
            onClick={() => {
              ReactGA.event({
                category: 'Button',
                action: 'Create course modal - Add course',
              });
              closeModal();
            }}
            variant="subtle"
            dataQa="courseModal-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            iconLeft={<IconPlus width={15} height={15} />}
            type="submit"
            disabled={!isValid}
            dataQa="courseModal-btn-add"
          >
            Create course record
          </Button>
        </ButtonRow>
      </Stack>
    </form>
  );
};

export default CreateCourse;
