import React, { SyntheticEvent, useEffect, useState } from 'react';
import ReactGA from '../../../utils/google-analytics';
import { ICourse } from '../../../context-providers/course/course-types';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import { fetchAPI } from '../../../utils/fetch-api';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';
import { IconEdit } from '../../icons';
import {
  CreateCourseHeader,
  CreateCourseSubtitle,
} from '../ModalContent.style';
import TutorNameFilter from '../../atoms/filter/filter-types/tutor-name-filter';
import {
  getAllQueryVar,
  urlWithSetMultipleQueryVar,
} from '../../../utils/query-vars';
import { navigate } from 'gatsby';
import { FilterItem } from '../../atoms/filter/models/filter-item';
import ReferenceDataFetcher, {
  ILearningAim,
} from '../../../utils/referenceDataFetcher';
import FilterSingleItem from '../../atoms/filter/FilterSingleItem';

interface IEditCourse {
  course: ICourse;
  onComplete: () => void;
}

const EditCourse = ({ course, onComplete }: IEditCourse) => {
  const closeModal = useCloseModal();

  const [editedCourse, setCourse] = useState<ICourse>(course);
  const [learningAims, setLearningAims] = useState<ILearningAim[]>([]);
  const [learningAimsLoaded, setLearningAimsLoaded] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const toggleMenu = (isMenuOpen: boolean): void => {
    setIsMenuOpen(isMenuOpen);
  };

  const extractQueryVars = (name: string): string[] => {
    const params = getAllQueryVar(name) ?? [];
    if (params.length === 1) {
      return params[0].split(',');
    }
    return params;
  };

  const getLearningAims = (): void => {
    if (!learningAimsLoaded) {
      ReferenceDataFetcher.getLearningAims().then((aimsresponse) => {
        setLearningAims(aimsresponse);
        setLearningAimsLoaded(true);
      });
    }
  };

  const learningAimOptions: FilterItem[] = learningAims.map((i) => ({
    id: i.learningAimId,
    name: i.learningAimTitle,
  }));

  const onAimChanged = (selectedItem: FilterItem): void => {
    const learningAim: ILearningAim | undefined = learningAims?.find(
      (l) => l.learningAimId === selectedItem.id,
    );
    const learningAimId = learningAim?.learningAimId ?? 0;
    const learningAimTitle = learningAim?.learningAimTitle ?? '';
    setCourse({ ...course, learningAimId, learningAimTitle });
  };

  const showCurrentTutors = () => {
    let url = '/';

    if (course.tutorIds !== null) {
      let idSplit: string[] = course.tutorIds.split(',');
      url = urlWithSetMultipleQueryVar(
        'tutors',
        idSplit.map((i) => i.toString()),
      );
      navigate(url);
    }
  };

  useEffect(() => {
    showCurrentTutors();
  }, []);

  useEffect(() => {
    getLearningAims();
  }, [getLearningAims]);

  const handleSubmit = async (event: SyntheticEvent) => {
    ReactGA.event({
      category: 'Button',
      action: 'Edit course modal - Update course',
    });

    const tutorsParams = extractQueryVars('tutors');
    editedCourse.tutors = tutorsParams.join(',');
    setCourse(editedCourse);

    event.preventDefault();

    const res = await fetchAPI({
      path: `Courses/${course.id}`,
      body: editedCourse,
      method: 'PUT',
      responseType: 'text',
    });
    if (res.success) {
      onComplete && onComplete();
      closeModal();
    }
  };

  const nameErr = validateField(editedCourse.name, { required: true });
  const isValid = !nameErr;

  return (
    <form
      onSubmit={(event) => {
        handleSubmit(event);
      }}
      style={isMenuOpen ? { maxWidth: 412, height: 550 } : { maxWidth: 412 }}
    >
      <Stack stackMultiplier={2}>
        <CreateCourseHeader>
          <IconEdit width={25} height={25} />
          <h2>Edit course record</h2>
        </CreateCourseHeader>
        <TextInput
          id="courseName"
          label="Course name"
          value={editedCourse.name}
          error={nameErr}
          dataQa="editCourse-input-name"
          onChangeText={(name) => setCourse({ ...editedCourse, name })}
        />
        <CreateCourseSubtitle>Learning Aim</CreateCourseSubtitle>
        <FilterSingleItem
          filterName="standard-filter"
          items={learningAimOptions}
          title="Learning Aim"
          searchTitle="Search Learning Aims"
          onItemChanged={onAimChanged}
          toggleMenu={toggleMenu}
          initalValue={{
            id: editedCourse.learningAimId,
            name: editedCourse.learningAimTitle,
          }}
        />
        <CreateCourseSubtitle>Assign Tutors</CreateCourseSubtitle>
        <TutorNameFilter toggleMenu={toggleMenu} />
        <ButtonRow>
          <Button
            onClick={() => {
              ReactGA.event({
                category: 'Button',
                action: 'Edit course modal - cancel',
              });
              closeModal();
            }}
            variant="subtle"
            dataQa="editCourse-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            iconLeft={<IconEdit />}
            type="submit"
            disabled={!isValid}
            dataQa="editCourse-btn-update"
          >
            Update course
          </Button>
        </ButtonRow>
      </Stack>
    </form>
  );
};

export default EditCourse;
