import React from 'react';
import Layout from '../../components/layout/Layout';
import Head from '../../components/layout/Head';
import CourseList from '../../components/organisms/course-list/CourseList';
import AuthWrapper from '../../components/layout/AuthWrapper';

const ListCoursesPage = () => {
  return (
    <AuthWrapper>
      <Head title="Courses" />
      <Layout
        section="courses"
        breadcrumbs={[{ link: '/', label: 'Home' }, { label: 'Courses' }]}
      >
        <CourseList />
      </Layout>
    </AuthWrapper>
  );
};

export default ListCoursesPage;
